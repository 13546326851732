<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <ComponentUserUploadExcel/>
        <ComponentUserList/>
        <ComponentUserListDatabase/>
        <!-- <ComponentUserListDatabaseExcel/> -->

        <ComponentUserMatched/>
        <!-- <ComponentUserMatchedDatabase/> -->
        <ComponentUserNotMatched/>
        <!-- <ComponentUserNotMatchedDatabase/>
        <ComponentUserListUnique/>
        <ComponentUserMatchedUnique/> -->
</v-container>
</template>
  
<script>
import { mapState } from 'vuex'
// import ComponentUserListDatabaseExcel from '../../components/users/ComponentUserListDatabaseExcel.vue';
import ComponentUserListDatabase from '../../components/users/ComponentUserListDatabase.vue';
import ComponentUserNotMatched from '../../components/users/ComponentUserNotMatched.vue';
// import ComponentUserListUnique from '../../components/users/ComponentUserListUnique.vue';
import ComponentUserUploadExcel from '../../components/users/ComponentUserUploadExcel.vue';
import ComponentUserList from '../../components/users/ComponentUserList.vue';
import ComponentUserMatched from '../../components/users/ComponentUserMatched.vue';
// import ComponentUserMatchedUnique from '../../components/users/ComponentUserMatchedUnique.vue';
// import ComponentUserNotMatchedDatabase from '../../components/users/ComponentUserNotMatchedDatabase.vue';
// import ComponentUserMatchedDatabase from '../../components/users/ComponentUserMatchedDatabase.vue';

export default {
    components:{
    ComponentUserUploadExcel,
    // ComponentUserListDatabaseExcel,
    ComponentUserNotMatched,
    // ComponentUserListUnique,
    ComponentUserList,
    ComponentUserListDatabase,
    ComponentUserMatched,
    // ComponentUserMatchedUnique,
    // ComponentUserNotMatchedDatabase,
    // ComponentUserMatchedDatabase
},
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        data:null,
        dataHeader:[
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = () => {
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
    },
    methods: {
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
    }
}
</script>